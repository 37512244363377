import React from "react";
import Private from "./Private";

import Layout from "../../pages/layout/Layout";
import Games from "../../pages/layout/games";
import CreateGame from "../../pages/layout/games/createGame";
import Rewards from "../../pages/layout/rewards";
import EditGame from "../../pages/layout/games/editGame";

export const Pages = {
  element: <Private />,
  children: [
    {
      element: <Layout />,
      //   errorElement: <RouterErrorPage />,
      children: [
        {
          path: "/games",
          element: <Games />,
        },
        {
          path: "/create_game",
          element: <CreateGame />,
        },
        {
          path: "/edit_game/:id",
          element: <EditGame />,
        },
        {
          path: "/rewards",
          element: <Rewards />,
        },
      ],
    },
  ],
};
