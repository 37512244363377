import { useQuery } from "react-query";
import { getGameDetails } from "../services/game/gameDetails";
import { getPlan } from "../services/game/apiGetPlan";

export const usePlan = (id) => {
  const { data, isLoading } = useQuery({
    queryKey: ["planDetails", id],
    queryFn: () => getPlan(id),
  });

  return { data, isLoading };
};
