import { useQuery } from "react-query";
import { getGameDetails } from "../services/game/gameDetails";
import { getRewardDetails } from "../services/rewards/apiRewardDetails";

export const useRewardDetail = (id) => {
  const { data, isLoading } = useQuery({
    queryKey: ["rewardDetails", id],
    queryFn: () => getRewardDetails(id),
  });

  return { data, isLoading };
};
