import { useQuery } from "react-query";
import { getGameDetails } from "../services/game/gameDetails";
import { getGroupId } from "../services/game/groupID";
import { getRewards } from "../services/game/apiReward";

export const useRewards = (pageNumber, pageSize) => {
  const { data, isLoading } = useQuery({
    queryKey: ["rewards",pageNumber,pageSize],
    queryFn: () => getRewards(pageNumber,pageSize),
  });
  //   const groupIds =  data?.data?.d ? JSON.parse(data?.data?.d)?.Table : ''
  // console.log("in userewards  == ", data.data);
  const rewards = data?.data;
  return { rewards, isLoading };
};
