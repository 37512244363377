import * as yup from "yup";

export const createRewardSchemas = yup
  .object({
    PlanId: yup.string().required("Plan id is required"),
    passcode: yup.string(),
    Name: yup.string().required("Reward name is required"),
    RewardType: yup.string().required("Reward type is required"),
    RewardExpiryDate: yup.string().required("Expiration date id required"),

    RewardDetails: yup
      .array()
      .of(
        yup.object().shape({
          Id: yup.number(),
          Position: yup.number(),
          StartPosition: yup
            .number()
            .required("Start position is required")
            .positive("Start position must be positive")
            .typeError("Start position is required"),
          EndPosition: yup
            .number()
            .required("End position is required")
            .positive("End position must be positive")
            .typeError("End position is required")
            .when('StartPosition', (start, schema) => {
              return schema.test({
                name: 'is-greater',
                message: 'must be greater than start position',
                test: (end) => end >= start,
              });
            }),
          RedeemPasscode: yup.string().required("Redeem passcode is required"),
          Description: yup.string().required("Description is required"),
          amount: yup.string(),
          PrizeMoney: yup
            .number()
            .required("Amount is required")
            .typeError("Amount is required")
            .positive("Amount must be positive"),
        })
      )
      .min(1, "At least one reward detail is required"),
  })
  .required();
