import * as yup from "yup";

export const editGame = yup
  .object({
    Description: yup.string().required("Game name is required"),
    template: yup.string(),
    GroupID: yup.string(),
    GameDetail: yup.string().required("Game details is required"),
    BannerFile: yup.string(),
    startDate: yup.string().required("Start date is required"),
    startTime: yup.string().required("Start Time is required"),
    endTime: yup.string().required("End Time is required"),
    MinPlayers: yup.string().required("Min players is required"),
    MaxPlayers: yup.string().required("Max players is required"),
    LeaderboardBanner: yup.string(),
    InGameTrading: yup.string(),
    VideoURL: yup.string(),
    RegistrationFee: yup.string().required("Registration fee is required"),
    TotalPrizeMoney: yup.string(),
    PayoutType:  yup.string(),
    PlanId:  yup.string(),
    Latitude: yup.string(),
    Longitude: yup.string(),
    IsSaveasTemplate: yup.string(),
    Status: yup.string(),
    NumRegistered:yup.string(),
  })
  .required();
