import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function ConfirmDialog({
  open,
  title = "Confirmation",
  content = "",
  confirmText = "Yes",
  onCancel,
  onConfirm,
}) {
  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={onCancel}
        aria-labelledby="customized-dialog-title"
        open={true}
        PaperProps={{
          style: {
            borderRadius: "10px",
            minWidth: "432px",
            
          },
        }}
      >
        <DialogTitle
          sx={{
            m: 0,
            backgroundColor: "rgba(40, 126, 173, 0.1)",
            fontSize: "20px",
            color: "rgba(27, 62, 97, 1)",
            fontFamily: "Lato",
            fontWeight: 700,
            borderBottom:1,
            borderColor:'rgba(27, 62, 97, 0.16)'
          
          }}
          id="customized-dialog-title"
        >
          {title}
          <IconButton
            aria-label="close"
            onClick={onCancel}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.background[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent style={{marginTop:'10px',padddingBottom:'0px'}}  >
          <Typography
            style={{
              textAlign: "center",
              fontWeight: 400,
              fontSize: "16px",
              color: "rgba(6, 0, 44, 0.7)",
             
            }}
            
          >
            {content}
          </Typography>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", margin: "10px" }}>
          <Button
            variant="contained"
            className="submit-button"
            type="button"
            onClick={onCancel}
            sx={{
              background: "transparent",
              border: "1px solid lightgray",
              "&:hover": {
                background: "transparent",
              },
              color: "gray",
              width: "130px",
              height: "45px",
              textTransform: "unset",
              boxShadow: "none",
            }}
          >
            {"Cancel"}
          </Button>
          <Button
            variant="contained"
            className="submit-button"
            type="button"
            onClick={onConfirm}
            sx={{
              width: "130px",
              height: "45px",
              background:
                "linear-gradient(90deg, rgba(236, 159, 5, 1) 0%, rgba(255, 78, 0, 1) 100%)",
              color: "#fff",
              "&:hover": {
                background:
                  "linear-gradient(90deg, rgba(236, 159, 5, 1) 0%, rgba(255, 78, 0, 1) 100%)",
              },
              textTransform: "unset",
            }}
          >
            {confirmText}
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </React.Fragment>
  );
}
