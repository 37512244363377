import { Button, IconButton, InputAdornment, Typography } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import RHFTextField from "../../components/RHF/RHFTextfield";
import { FormProvider, useForm } from "react-hook-form";
import { loginApi } from "../../services/auth/login";
import logo from "../../assets/svg/login-header.svg";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginForm } from "../../schemas/auth/login";
import Spinner from "../../components/Spinner";
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";
import toast from "react-hot-toast";
import "../../styles/Login.css";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const methods = useForm({
    resolver: yupResolver(loginForm),
  });

  const handleSubmit = async (formData) => {
    console.log("formdata == ", formData);
    setLoading(true);
    loginApi(formData)
      .then((res) => {
        console.log("login response == ", res);
        if (res.data.status == 1) {
          toast.success(res.data.message);
          localStorage.setItem("token", res.data.data.token);
          navigate("/games");
        } else {
          toast.error(res.data.message);
        }
      })
      .catch((err) => {
        console.log("login error == ", err);
        toast.error('Network error')
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) {
    return (
      <div className="container">
        <div className="login-container">
          <Spinner />
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="login-container">
        <div className="image-div">
          <img src={logo} alt="DayTrader Logo" />
          <div className="login-box">
            <Typography
              sx={{
                fontSize: 28,
                fontWeight: 700,
                fontFamily: "Overpass !important",
              }}
            >
              Sign In
            </Typography>
            <Typography
              style={{
                paddingBottom: 2,
                fontWeight: 400,
                fontSize: 16,
                color: "rgba(255, 255, 255, 0.8)",
                marginBottom: "25px",
                lineHeight: "19.2px",
              }}
            >
              Enter your email and password to
              <br /> access your account
            </Typography>
            <FormProvider {...methods}>
              <form onSubmit={methods.handleSubmit(handleSubmit)}>
                <RHFTextField
                  name="username"
                  variant="outlined"
                  label="Email Address"
                  type="email"
                  fullWidth
                  InputLabelProps={{
                    style: { color: "rgba(255,255,255,0.6)", fontSize: "14px" },
                  }}
                  InputProps={{
                    style: {
                      color: "rgba(255,255,255,0.6)",
                      backgroundColor: "transparent",
                      borderRadius: "8px",

                      fontSize: "14px",
                    },
                    notchedOutline: {
                      borderColor: "rgba(255,255,255,0.6)", // Border color
                    },
                  }}
                  FormHelperTextProps={{
                    sx: {
                      color: "red", // Customize error message color
                      fontSize: 12, // Adjust font size
                      marginLeft: "0",
                      position: "absolute",
                      top: 50,
                    },
                  }}
                  sx={{
                    marginBottom: "25px",
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "rgba(255,255,255,0.6)", // Default border color
                      },
                      "&:hover fieldset": {
                        borderColor: "rgba(255,255,255,0.6)", // Border color on hover
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "rgba(255,255,255,0.5)", // Border color when focused
                      },
                    },
                    "& input": {
                      "&:-webkit-autofill": {
                        WebkitBoxShadow:
                          "0 0 0 100px rgba(6, 0, 44, 0.9) inset", // Background color when autofilled
                        WebkitTextFillColor: "rgba(255,255,255,0.6)", // Text color in autofilled state
                      },
                    },
                  }}
                />
                <RHFTextField
                  variant="outlined"
                  label="Password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  fullWidth
                  InputLabelProps={{
                    style: { color: "rgba(255,255,255,0.6)", fontSize: "14px" },
                  }}
                  FormHelperTextProps={{
                    sx: {
                      color: "red",
                      fontSize: 12,
                      marginLeft: "0",
                      position: "absolute",
                      top: 50,
                    },
                  }}
                  InputProps={{
                    notchedOutline: {
                      borderColor: "rgba(255,255,255,0.6)",
                    },
                    style: {
                      color: "rgba(255,255,255,0.6)",
                      backgroundColor: "transparent",
                      borderRadius: "8px",
                      fontSize: "14px",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          edge="end"
                          sx={{ color: "gray" }}
                          aria-label="toggle password visibility"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "rgba(255,255,255,0.6)", // Default border color
                      },
                      "&:hover fieldset": {
                        borderColor: "rgba(255,255,255,0.6)", // Border color on hover
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "rgba(255,255,255,0.5)", // Border color when focused
                      },
                    },
                    "& input": {
                      "&:-webkit-autofill": {
                        WebkitBoxShadow:
                          "0 0 0 100px rgba(6, 0, 44, 0.9) inset", // Background color when autofilled
                        WebkitTextFillColor: "rgba(255,255,255,0.6)", // Text color in autofilled state
                      },
                    },
                  }}
                />
                <Button
                  variant="contained"
                  className="login-button"
                  type="submit"
                  sx={{
                    background:
                      "linear-gradient(90deg, rgba(236, 159, 5, 1) 0%,  rgba(255, 78, 0, 1) 100%)",
                    color: "#fff",
                    "&:hover": {
                      background:
                        "linear-gradient(90deg, rgba(236, 159, 5, 1) 0%, rgba(255, 78, 0, 1) 100%)",
                    },
                    padding: "12px",
                    fontSize: "14px",
                    marginTop: "25px",
                  }}
                >
                  Sign In
                </Button>
              </form>
            </FormProvider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
