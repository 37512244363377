import * as yup from "yup";

export const createGameSchema = yup
  .object({
    Description: yup.string().required("Game name is required"),
    template: yup.string(),
    GroupID: yup.string(),
    GameDetail: yup.string().required("Game details is required"),
    BannerFile: yup.string(),
    startDate: yup.string().required("Start date is required"),
    startTime: yup.string().required("Start Time is required"),
    endTime: yup.string().required("End Time is required"),
    MinPlayers: yup
      .number()
      .required("Min players is required")
      .typeError("Min players is required")
      .positive("Min players must be positive"),
    MaxPlayers: yup
      .number()
      .required("Max players is required")
      .typeError("Max players is required")
      .positive("Max players must be positive")
      .when("MinPlayers", (minPyr, schema) => {
        return schema.test({
          name: "is-greater",
          message: "must be greater than min players",
          test: (maxPyr) => maxPyr >= minPyr,
        });
      }),
    LeaderboardBanner: yup.string(),
    InGameTrading: yup.string(),
    VideoURL: yup.string(),
    RegistrationFee: yup
      .number()
      .required("Registration fee is required")
      .typeError("Registration fee is required"),
    TotalPrizeMoney: yup.string().when("RegistrationFee", (rgsFee, schema) => {
      return rgsFee > 0
        ? schema.required("Total prize is required")
        : schema.notRequired();
    }),
    PayoutType: yup.string().when("RegistrationFee", (rgsFee, schema) => {
      return rgsFee > 0
        ? schema.required("Payout ratio is required")
        : schema.notRequired();
    }),
    PlanId: yup.string().when("RegistrationFee", (rgsFee, schema) => {
      return rgsFee == 0
        ? schema.required("Plan id is required")
        : schema.notRequired();
    }),
    Latitude: yup.string(),
    Longitude: yup.string(),
    IsSaveasTemplate: yup.string(),
    Status: yup.string(),
    NumRegistered: yup.string(),
  })
  .required();
