export function removeHtmlTagsAndEntities(str) {
  var htmlTagPattern = /<[^>]*>/g;

  var htmlEntityPattern = /&[^;]+;/g;

  var cleanStr = str.replace(htmlTagPattern, "");

  cleanStr = cleanStr.replace(htmlEntityPattern, "");
  return cleanStr;
}
