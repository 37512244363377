import React, { useEffect, useState } from "react";
import GameComponent from "../../../../components/GameComponent";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Spinner from "../../../../components/Spinner";
import { useGameDetail } from "../../../../queries/gameDetails";
import { useGroupIds } from "../../../../queries/groupId";
import { useRewards } from "../../../../queries/rewards";
import { getLeaderboard } from "../../../../services/game/apiLeaderboard";

function EditGame() {

  const { id } = useParams();
  const { data, isLoading } = useGameDetail(id);
  const { groupIds } = useGroupIds();
  const { rewards } = useRewards();
 

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <GameComponent
      gameData={data?.data?.Table[0]}
      groups={groupIds}
      plans={rewards}
      id={id}
    />
  );
}

export default EditGame;
