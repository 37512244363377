import { QueryClient, QueryClientProvider, useQueryClient } from "react-query";
import "../src/styles/global.css";
import Router from "./router";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";

export default function App() {
  const queryClient = new QueryClient();
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <QueryClientProvider client={queryClient}>
        <Router />
      </QueryClientProvider>
    </LocalizationProvider>
  );
}
