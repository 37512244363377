import Api from "../axios";

export const createGame = async (data) => {
  return await Api.post("/Game/CreateGame", data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "*/*",
      "ngrok-skip-browser-warning": "1",
    },
  });
};
