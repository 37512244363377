import {
  Box,
  MenuItem,
  Button,
  IconButton,
  Grid,
  Typography,
  Stack,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import "../styles/gamedetail.css";
import img from "../assets/svg/image-picker-icon.png";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { editGame } from "../schemas/game/editGame";
import { RHFSelect } from "./RHF/RHFSelect";
import RHFTextField from "./RHF/RHFTextfield";
import RHFDatepicker from "./RHF/RHFDatepickers";
import { RHFCheckbox } from "./RHF/RHFCheckbox";
import RHFTimePicker from "./RHF/RHFTimepicker";
import dayjs from "dayjs";
import playIcon from "../assets/svg/play.svg";
import planeIndIcon from "../styles/images/planId_icon.svg";

import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { updateGame } from "../services/game/updateGame";
import { updateGameStatus } from "../services/game/apiUpdateGameStatus";

import Spinner from "./Spinner";
import toast from "react-hot-toast";
import VideoComponent from "./VideoPopup/VideoPopup";
import ViewPlanPopup from "../pages/layout/games/viewPlanId";
import LeaderboardTable from "./LeaderboardTable";
import { getLeaderboard } from "../services/game/apiLeaderboard";
import ConfirmDialog from "./ConfirmDialog";
import { useNavigate } from "react-router-dom";
import RHFTextEditor from "./RHF/RHFTexteditor";
import { createGameSchema } from "../schemas/game/createGame";

export default function GameComponent({ gameData, groups, plans, id }) {
  const methods = useForm({
    resolver: yupResolver(createGameSchema),
    defaultValues: {
      Description: gameData?.Description || "",
      template: "",
      GroupID: gameData?.GroupID,
      GameDetail: gameData?.GameDetail,
      MinPlayers: gameData?.GameDetail ? +gameData.MinPlayers : "",
      MaxPlayers: gameData?.MaxPlayers ? +gameData.MaxPlayers : "",
      InGameTrading: gameData?.InGameTrading == "true" ? true : false,
      VideoURL: gameData?.VideoURL,
      RegistrationFee: gameData?.RegistrationFee
        ? (+gameData.RegistrationFee).toFixed(2)
        : "",
      TotalPrizeMoney: gameData?.TotalPrizeMoney
        ? (+gameData.TotalPrizeMoney).toFixed(2)
        : "",
      PayoutType: gameData?.PayoutType,
      PlanId: gameData?.PlanId,
      Latitude: gameData?.Latitude,
      Longitude: gameData?.Longitude,
      IsSaveasTemplate: gameData?.IsSaveasTemplate,
      BannerFile: gameData?.BannerFile,
      LeaderboardBanner: gameData?.LeaderboardBanner,
      startDate: gameData?.StartDateTime ? dayjs(gameData.StartDateTime) : "",
      startTime: gameData?.StartDateTime ? dayjs(gameData.StartDateTime) : "",
      endTime: gameData?.EndDateTime ? dayjs(gameData.EndDateTime) : "",
      Status: gameData?.Status ? gameData.Status : "",
    },
  });
  const statusOptions = [
    { key: "Pending", value: "OPEN" },
    { key: "Running", value: "RUNNING" },
    { key: "Completed", value: "COMPLETED" },
    { key: "Cancelled", value: "CANCELLED" },
  ];
  const navigate = useNavigate();
  const [viewPlan, setViewPlan] = useState(false);
  const [viewVideo, setViewVideo] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const [leaderboardData, setLeaderboardData] = useState([]);
  console.log("Game Data ========", gameData);
  useEffect(() => {
    if (gameData?.Status === "COMPLETED" || gameData?.Status === "RUNNING") {
      setLoading(true);
      getLeaderboard({ GameID: id })
        .then((res) => {
          setLeaderboardData(res);
          console.log(res, "RESSPINSE FROM API");
        })
        .catch((er) => {})
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLeaderboardData([]);
    }
  }, []);

  const onSubmit = (formData) => {
    console.log("form data === ", formData);
    const startDate = formData.startDate;
    const startTime = formData.startTime;
    const endTime = formData.endTime;
    const StartDateTime = dayjs(
      `${dayjs(startDate).format("YYYY-MM-DD")} ${dayjs(startTime).format(
        "HH:mm:ss.SSS"
      )}`
    ).format("YYYY-MM-DD HH:mm:ss.SSS");
    const EndDateTime = dayjs(
      `${dayjs(startDate).format("YYYY-MM-DD")} ${dayjs(endTime).format(
        "HH:mm:ss.SSS"
      )}`
    ).format("YYYY-MM-DD HH:mm:ss.SSS");
    const GameID = gameData?.GameID;
    const MaxPlayers = parseInt(formData.MaxPlayers);
    const MinPlayers = parseInt(formData.MinPlayers);
    const RegistrationFee = parseFloat(formData.RegistrationFee);
    const Latitude = formData.Latitude ? parseFloat(formData.Latitude) : "";
    const Longitude = formData.Longitude ? parseFloat(formData.Longitude) : "";

    formData = {
      ...formData,
      GameID,
      StartDateTime,
      EndDateTime,
      MaxPlayers,
      MinPlayers,
      RegistrationFee,
      Latitude,
      Longitude,
    };

    console.log("form data with datetime === ", formData);
    setLoading(true);
    updateGame(formData)
      .then((res) => {
        console.log("create game response === ", res);
        toast.success("Game Updated Successfully.");
        console.log(gameData?.GameID, "gameData?.GameID");
        onUpdateGameStatus();
      })
      .catch((err) => {
        const status = err.response.status;
        setLoading(false);
        if (status === 400) {
          toast.error("Bad Request. Please check your input and try again.");
        } else if (status === 401) {
          toast.error("Unauthorized. Please login again.");
        } else if (status === 500) {
          toast.error("Server Error. Please try again later.");
        } else {
          toast.error(
            `Error: ${err.response.statusText || "Something went wrong."}`
          );
        }
        console.log("create game error == ", err);
      })
      .finally(() => {});
  };
  const onUpdateGameStatus = () => {
    setLoading(true);
    let data = {
      GameID: gameData?.GameID,
      Status: methods.getValues("Status"),
    };
    console.log(methods.getValues("Status"), "methods.getValues");
    updateGameStatus(data)
      .then((res) => {
        console.log("res from update game status", res);
        toast.success("Game status updated");
        navigate("/games");
        setLoading(false);
      })
      .catch((er) => {
        toast.error("Error in updating the game status");
        console.log(er, "Error in Updating game status");
        setLoading(false);
      });
  };
  // Function to check if the game can be edited based on its status
  const canEditGame = (status) => {
    console.log(
      status,
      "status",
      "gameData?.NumRegistered",
      gameData?.NumRegistered
    );
    switch (status) {
      case "OPEN":
        if (gameData?.NumRegistered > 0) {
          return {
            canEdit: true, // Allow editing
            canUpdateFees: false, // Cannot update registration fees
          };
        } else {
          return {
            canEdit: true, // Allow editing
            canUpdateFees: true, // Cannot update registration fees
          };
        }

      case "RUNNING":
        return {
          canEdit: false, // Allow editing
          canUpdateFees: false, // Cannot update registration fees
        };

      case "COMPLETED":
        return {
          canEdit: false, // Cannot edit
          canUpdateFees: false, // Cannot update registration fees
        };
      case "CANCELLED":
        return {
          canEdit: false, // Cannot edit
          canUpdateFees: false, // Cannot update registration fees
        };
      default:
        return {
          canEdit: true, // Allow editing for unknown statuses
          canUpdateFees: true, // Allow updating registration fees for unknown statuses
        };
    }
  };

  const status = gameData?.Status;
  const { canEdit, canUpdateFees } = canEditGame(status);
  const isDisable = !canEdit;
  const disableFees = !canUpdateFees;

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div>
      <Typography fontFamily={"Lato"} fontSize={28} fontWeight={700}>
        Game Detail
      </Typography>

      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Box className="game-detail-container">
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <RHFTextField
                  disabled={isDisable}
                  InputProps={{
                    readOnly: isDisable,
                  }}
                  name="Description"
                  label="Game Name"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} md={4}>
                {groups?.length > 0 && (
                  <RHFSelect
                    disabled={isDisable}
                    name="GroupID"
                    label="Group ID"
                    style={{
                      color: "rgba(81, 92, 99, 1)",
                      fontSize: "14px",
                    }}>
                    {groups?.map((item) => {
                      return (
                        <MenuItem
                          sx={{
                            fontSize: "14px",
                            color: "rgba(81, 92, 99,1)",
                          }}
                          value={item.GroupID}>
                          {item.GroupID}
                        </MenuItem>
                      );
                    })}
                  </RHFSelect>
                )}
              </Grid>

              <Grid item xs={12} md={8} height={106}>
                <RHFTextEditor
                  name="GameDetail"
                  placeholder="Enter game details"
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <div className="image-section">
                  <div className="image-upload">
                    <label className="floating-label">Banner Image</label>
                    <div onClick={() => {}}>
                      {gameData?.BannerFile ? (
                        <img
                          src={gameData?.BannerFile}
                          alt="Banner"
                          className="uploaded-img"
                        />
                      ) : (
                        <div className="upload-placeholder">
                          <img
                            src={img}
                            alt="Upload Icon"
                            className="upload-icon"
                          />
                          <Typography
                            style={{
                              fontWeight: "normal",
                              fontSize: "14px",
                              color: "rgba(81, 92, 99, 0.6)",
                            }}>
                            Drag & Drop or{" "}
                            <span className="browse-btn">Browse</span>
                          </Typography>
                        </div>
                      )}
                      <input
                        type="file"
                        accept="image/*"
                        name="BannerFile"
                        // ref={bannerInputRef}
                        style={{ display: "none" }}
                      />
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item container spacing={2} xs={12} md={8}>
                <Grid item xs={12} md={4}>
                  <RHFDatepicker
                    InputProps={{ readOnly: isDisable }}
                    name="startDate"
                    label="Date"
                    placeholder="date"
                    disabled={isDisable}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <RHFTimePicker
                    disabled={isDisable}
                    name="startTime"
                    label="Start Time"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <RHFTimePicker
                    disabled={isDisable}
                    name="endTime"
                    label="End Time"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <RHFTextField
                    disabled={isDisable}
                    InputProps={{ readOnly: isDisable }}
                    name="MinPlayers"
                    label="Min Players"
                    variant="outlined"
                    fullWidth
                    type="number"
                    className="form-field"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <RHFTextField
                    disabled={isDisable}
                    InputProps={{
                      readOnly: isDisable,
                    }}
                    name="MaxPlayers"
                    label="Min Players"
                    variant="outlined"
                    fullWidth
                    type="number"
                    className="form-field"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className="image-section">
                  <div className="image-upload">
                    <label className="floating-label">Leaderboard Banner</label>
                    <div>
                      {gameData?.LeaderboardBanner ? (
                        <img
                          src={gameData?.LeaderboardBanner}
                          alt="Leaderboard Banner"
                          className="uploaded-img"
                        />
                      ) : (
                        <div className="upload-placeholder">
                          <IconButton color="primary" component="span">
                            <img
                              src={img}
                              alt="Upload Icon"
                              className="upload-icon"
                            />
                          </IconButton>
                          <Typography
                            style={{
                              fontWeight: 400,
                              fontSize: "14px",
                              color: "rgba(81, 92, 99, 0.6)",
                            }}>
                            Drag & Drop or{" "}
                            <span
                              style={{
                                fontSize: "12px",
                                border: "1px solid rgba(40, 126, 173, 0.1)",
                                borderRadius: 8,
                                color: "rgba(40, 126, 173, 0.8)",
                                backgroundColor: "rgba(40, 126, 173, 0.1)",
                                padding: "5px",
                              }}>
                              Browse
                            </span>
                          </Typography>
                        </div>
                      )}
                      <input
                        type="file"
                        accept="image/*"
                        name="LeaderboardBanner"
                        style={{ display: "none" }}
                      />
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={12} md={4}>
                <RHFCheckbox
                  name="InGameTrading"
                  disabled={isDisable}
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 24, // Reduce checkbox size
                      borderRadius: "10px", // Optional: add border radius
                    },
                    "&.Mui-checked": {
                      color: "rgba(40, 126, 173, 1)", // Set the check icon color to red
                    },
                    "& .MuiFormControlLabel-label": {
                      fontSize: "14px", // Change the font size of the label
                      color: "rgba(81, 92, 99, 0.6)", // Change the color of the label
                    },
                  }}
                  label="Allow In Game Trading"
                  style={{ color: "rgba(81, 92, 99, 0.6)", fontSize: "12px" }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <RHFTextField
                  disabled={disableFees || isDisable} // Disable if not allowed to update fees
                  name="RegistrationFee"
                  label="Registration Fee($)"
                  variant="outlined"
                  fullWidth
                  className="form-field"
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <RHFTextField
                  disabled={isDisable}
                  InputProps={{
                    readOnly: isDisable,
                  }}
                  label="Latitude"
                  name="Latitude"
                  variant="outlined"
                  fullWidth
                  className="form-field"
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <RHFTextField
                  disabled={isDisable}
                  InputProps={{
                    readOnly: isDisable,
                  }}
                  label="Longitude"
                  name="Longitude"
                  variant="outlined"
                  fullWidth
                  className="form-field"
                />
              </Grid>

              <Grid item container spacing={2} xs={12} md={8}>
                {methods.watch("RegistrationFee") > 0 && (
                  <Grid item xs={12} md={6}>
                    <RHFTextField
                      disabled={isDisable}
                      InputProps={{
                        readOnly: isDisable,
                      }}
                      label="Total Prize Money ($)"
                      name="TotalPrizeMoney"
                      variant="outlined"
                      fullWidth
                      className="form-field"
                    />
                  </Grid>
                )}
                {methods.watch("RegistrationFee") > 0 && (
                  <Grid item xs={12} md={6}>
                    <RHFTextField
                      disabled={isDisable}
                      InputProps={{
                        readOnly: isDisable,
                      }}
                      label="Payout Ratio"
                      name="PayoutType"
                      variant="outlined"
                      fullWidth
                      className="form-field"
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={12}>
                  <Stack direction={"row"}>
                    <RHFTextField
                      disabled={isDisable}
                      InputProps={{
                        readOnly: isDisable,
                      }}
                      name="VideoURL"
                      variant="outlined"
                      label="Promo Video URL"
                      fullWidth
                      className="form-field"
                    />
                    {methods.watch("VideoURL") ? (
                      <img
                        src={playIcon}
                        alt="play"
                        onClick={() => setViewVideo(true)}
                      />
                    ) : (
                      ""
                    )}
                  </Stack>
                </Grid>
                {methods.watch("RegistrationFee") == 0 && (
                  <Grid item xs={12} md={12}>
                    <Stack direction={"row"}>
                      {plans?.length > 0 && (
                        <RHFSelect
                          disabled={isDisable}
                          name="PlanId"
                          label="Plan"
                          style={{ color: "rgba(81, 92, 99, 1)" }}>
                          {plans?.map((item) => (
                            <MenuItem
                              sx={{
                                fontSize: "14px",
                                color: "rgba(81, 92, 99, 1)",
                              }}
                              key={item.planId}
                              value={item.planId}>
                              {item.name}
                            </MenuItem>
                          ))}
                        </RHFSelect>
                      )}

                      {methods.watch("PlanId") || gameData?.planId ? (
                        <img
                          src={planeIndIcon}
                          alt="plan"
                          onClick={() => setViewPlan(true)}
                        />
                      ) : (
                        ""
                      )}
                    </Stack>
                  </Grid>
                )}
              </Grid>

              <Grid item xs={12} md={4}>
                {(gameData?.Latitude && gameData?.Longitude) ||
                  (methods.watch("Latitude") && methods.watch("Longitude") && (
                    <LoadScript googleMapsApiKey="AIzaSyB7eYRHUhX2ACrnGw6seFkQRlQ_mzel53Q">
                      <GoogleMap
                        mapContainerStyle={{ width: "100%", height: "140px" }}
                        center={{
                          lat: parseFloat(gameData?.Latitude) || 0,
                          lng: parseFloat(gameData?.Longitude) || 0,
                        }}
                        zoom={10}>
                        <Marker
                          position={{
                            lat: parseFloat(gameData?.Latitude) || 0,
                            lng: parseFloat(gameData?.Longitude) || 0,
                          }}
                        />
                      </GoogleMap>
                    </LoadScript>
                  ))}
              </Grid>

              <Grid item xs={12} md={3}>
                {statusOptions?.length > 0 && (
                  <RHFSelect
                    name="Status"
                    disabled={isDisable}
                    label="Status"
                    defaultValue={gameData?.Status || statusOptions[0].value} // Default to first option if no status is present
                    onChange={(e) => {
                      methods.setValue("Status", e.target.value);
                      if (e.target.value == "CANCELLED") {
                        setOpen(true);
                      }
                    }}
                    variant="outlined"
                    fullWidth
                    style={{ color: "rgba(81, 92, 99, 1)" }}>
                    {statusOptions.map((status) => (
                      <MenuItem
                        sx={{
                          fontSize: "14px",
                          color: "rgba(81, 92, 99, 1)",
                        }}
                        key={status.value}
                        value={status.value}>
                        {status.key}
                      </MenuItem>
                    ))}
                  </RHFSelect>
                )}
              </Grid>
            </Grid>
            <div className="flex-container">
              <Button
                disabled={isDisable}
                variant="contained"
                className="submit-button"
                type="submit"
                sx={{
                  background:
                    "linear-gradient(90deg, rgba(236, 159, 5, 1) 0%,  rgba(255, 78, 0, 1) 100%)",
                  color: "#fff", // Ensure the text color is readable
                  "&:hover": {
                    background:
                      "linear-gradient(90deg, rgba(236, 159, 5, 1) 0%, rgba(255, 78, 0, 1) 100%)",
                  },
                  textTransform: "unset",
                }}>
                Update
              </Button>
            </div>
          </Box>
        </form>
      </FormProvider>
      {open && (
        <ConfirmDialog
          confirmText={"OK"}
          title={"Game Warning"}
          onCancel={() => setOpen(false)}
          onConfirm={onUpdateGameStatus}
          content={
            <p>
              Are you sure? Once cancelled,this game <br />
              cannot be restored.
            </p>
          }
        />
      )}
      {viewVideo ? (
        <VideoComponent
          methods={methods}
          handleClose={() => setViewVideo(false)}
        />
      ) : (
        ""
      )}
      {viewPlan ? (
        <ViewPlanPopup
          open={viewPlan}
          handleClose={() => setViewPlan(false)}
          id={methods.getValues("PlanId")}
        />
      ) : (
        ""
      )}
      {(gameData?.Status === "COMPLETED" || gameData?.Status === "RUNNING") &&
        leaderboardData.length > 0 && (
          <Stack>
            <LeaderboardTable leaderboardData={leaderboardData} />
          </Stack>
        )}
    </div>
  );
}
