import React from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../../components/SidebarNew";
import "../../styles/dashboard.css";
export default function Layout() {
  return (
    <div className="mainView">
      <Sidebar />
      <div className="outer">
        <Outlet />
      </div>
    </div>
  );
}