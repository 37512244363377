import React from "react";
import { LinearProgress, Tooltip, Box, Typography } from "@mui/material";

export default function renderParticipants(params) {
  const { NumRegistered, MinPlayers, MaxPlayers } = params.row;
  console.log(params.row);

  // Calculate the percentage of players relative to the max players
  const percentage = (NumRegistered / MaxPlayers) * 100;

  // Determine the color based on the number of registered players
  const progressColor = NumRegistered < MinPlayers ? "red" : "green";

  // Tooltip content
  const tooltipContent = `${NumRegistered}-${MinPlayers}/${MaxPlayers}`;

  return (
    <Tooltip title={tooltipContent}>
      <Box alignItems="center" width="100%">
        <LinearProgress
          variant="determinate"
          value={percentage}
          style={{ height: 10, borderRadius: 5, backgroundColor: "#f0f0f0" }}
          sx={{
            "& .MuiLinearProgress-bar": {
              backgroundColor: progressColor,
            },
          }}
        />
        <Box width={200}></Box>
      </Box>
    </Tooltip>
  );
}
