import { Controller, useFormContext } from 'react-hook-form';

import {TextField} from '@mui/material';

// ----------------------------------------------------------------------

export default function RHFTextField({ name ,sx, ...other}) {
  const { control } = useFormContext();
  
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          fullWidth
          error={!!error}
          sx={{ 
            ...sx,
            "& .MuiInputBase-input": {
               padding: "9.5px 14px" ,
               height: "52px !important",
              } ,
   
             
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor:    'rgba(40, 126, 173, 0.12)', // Red border on error
                  color:    'rgba(81, 92, 99, 0.6)', 
                 
                },
                '&:hover fieldset': {
                  borderColor:    'rgba(40, 126, 173, 0.12)', // Red border on hover if error
                },
                '&.Mui-focused fieldset': {
                  borderWidth: 1,
                  borderColor:    'rgba(40, 126, 173, 0.12)', // Red border on focus if error
                },
              },
              '& .MuiInputBase-input': {
                color: 'rgba(81, 92, 99, 1)', // Change text color here
                fontSize: '14px', // Font size for input conten
              },
              '& .MuiInputBase-input::placeholder': {
                color: 'rgba(81, 92, 99, 0.1)', 
                fontSize: '14px', // Font size for input conten
              },
           
          }}
          InputLabelProps={{
            style:{
              fontSize:'14px',
              color:'rgba(81, 92, 99, 0.6)'
            }
          }}
          helperText={error ? error?.message : ''}
          {...field}
        {...other}
        />
      )}
    />
  );
}