import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import RHFTextField from "../../../../components/RHF/RHFTextfield";
import { RHFSelect } from "../../../../components/RHF/RHFSelect";
import { useRewards } from "../../../../queries/rewards";
import { createRewardSchemas } from "../../../../schemas/rewards/createRewards";
import deleteStepIcon from "../../../../styles/images/delete_reward_step.svg";
import RHFDatepicker from "../../../../components/RHF/RHFDatepickers";
import { postCreateReward } from "../../../../services/rewards/apiCreateRewards";
import moment from "moment";
import toast from "react-hot-toast";
import { getNextReward } from "../../../../services/rewards/apiGetRewardId";
import { useQueryClient } from "react-query";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function CreateRewards({
  open,
  children,
  handleClose,
  callback,
  loading,
  title,
  reward,
  btnColor = "#1B3E61",
  selectedQuery,
}) {
  const theme = useTheme();
  const { rewards } = useRewards();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [nextId, setNExtId] = React.useState("");
  const queryClient = useQueryClient();
  const methods = useForm({
    resolver: yupResolver(createRewardSchemas),
    defaultValues: {
      PlanId: "",
      passcode: "",
      Name: "",
      RewardType: "",
      RewardDetails: [
        {
          Id: 0,
          Position: 0,
          StartPosition: 0,
          EndPosition: 0,
          RedeemPasscode: "",
          Description: "",
          amount: "",
          PrizeMoney: 0,
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control: methods.control,
    name: "RewardDetails",
  });

  React.useEffect(() => {
    getNextReward()
      .then((res) => {
        console.log("next reward id == ", res.data);
        methods.setValue("PlanId", res.data);
      })
      .catch((err) => {
        console.log("next reward id error == ", err);
      });
  }, []);

  const onSubmit = (formData) => {
    console.log("form data == ", formData);

    const rewardData = {
      PlanId: parseInt(formData.PlanId),
      Name: formData.Name,
      RewardType: formData.RewardType,
      RewardExpiryDate: moment(formData.RewardExpiryDate).toISOString(),
      RewardDetails: formData.RewardDetails.map((range) => ({
        Id: range.Id,
        StartPosition: parseInt(range.StartPosition, 10),
        EndPosition: parseInt(range.EndPosition, 10),
        RedeemPasscode: range.RedeemPasscode || "",
        Description: range.Description || "",
        PrizeMoney: parseFloat(range.PrizeMoney) || 0,
      })),
    };

    postCreateReward(rewardData)
      .then((res) => {
        console.log("create rewards response == ", res);
        toast.success(res.data);
        queryClient.invalidateQueries({
          queryKey: ["rewards", selectedQuery.page, selectedQuery.pageSize],
        });
        handleClose();
      })
      .catch((err) => {
        console.log("create rewards error  = ", err);
      });
  };
  console.log("errors of react-hook-form", methods.formState.errors);
  return (
    <React.Fragment>
      <BootstrapDialog
        fullWidth
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        open={open}
        fullScreen={fullScreen}
        sx={{
          height: "100vh",
          "& .MuiPaper-root": {
            borderRadius: "12px",
            maxWidth: "716px",
          },
        }}>
        <DialogTitle
          sx={{
            m: 0,
            padding: "16px",
            color: "rgba(27, 62, 97, 1)",
            fontFamily: "Lato",
            fontWeight: 600,
            bgcolor: "rgba(40, 126, 173, 0.1)",
            lineHeight: "1",
          }}
          id="responsive-dialog-title"
          className="dialog-title">
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
        <DialogContent dividers className="dialog-content-form">
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <RHFTextField name="PlanId" disabled label="Plan Id" />
                </Grid>
                <Grid item xs={6}>
                  <RHFTextField name="passcode" label="Passcode" fullWidth />
                </Grid>
                <Grid item xs={6}>
                  <RHFTextField name="Name" fullWidth label="Reward Name" />
                </Grid>
                <Grid item xs={6}>
                  <RHFSelect
                    name="RewardType"
                    fullWidth
                    label="Reward Type"
                    style={{
                      color: "rgba(81, 92, 99, 1)",
                      fontSize: "14px",
                    }}>
                    <MenuItem
                      sx={{
                        fontSize: "14px",
                        color: "rgba(81, 92, 99, 0.6)",
                      }}
                      value={"Cash"}>
                      {"Cash"}
                    </MenuItem>
                    <MenuItem
                      sx={{
                        fontSize: "14px",
                        color: "rgba(81, 92, 99, 0.6)",
                      }}
                      value={"NONCASH"}>
                      {"Non Cash"}
                    </MenuItem>
                  </RHFSelect>
                </Grid>
                <Grid item xs={6}>
                  <Typography
                    color="#1B3E61"
                    marginBottom={1}
                    fontFamily={"Lato"}
                    fontSize={16}
                    fontWeight={600}>
                    Reward Range
                  </Typography>
                  {methods.formState.errors.RewardDetails?.message && (
                    <Typography
                      color="#d32f2f"
                      fontWeight={400}
                      fontSize={12}
                      mb={1}>
                      {methods.formState.errors.RewardDetails.message}
                    </Typography>
                  )}
                </Grid>
                {fields.map((field, index) => (
                  <Box m={2} key={field.id}>
                    <Stack spacing={2} direction="row" key={field.id}>
                      <RHFTextField
                        name={`RewardDetails[${index}].StartPosition`}
                        InputProps={{ inputProps: { min: 0 } }}
                        type="number"
                        label="Start Position"
                      />
                      <RHFTextField
                        name={`RewardDetails[${index}].EndPosition`}
                        InputProps={{ inputProps: { min: 0 } }}
                        type="number"
                        label="End Position"
                      />

                      <RHFTextField
                        name={`RewardDetails[${index}].Description`}
                        label="Description"
                        sx={{
                          width: "120% !important",
                        }}
                      />

                      <RHFTextField
                        name={`RewardDetails[${index}].PrizeMoney`}
                        label="Amount ($)"
                        InputProps={{ inputProps: { min: 0 } }}
                        type="number"
                        sx={{
                          "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                            {
                              display: "none",
                            },
                          "& input[type=number]": {
                            MozAppearance: "textfield",
                          },
                        }}
                      />
                      <RHFTextField
                        name={`RewardDetails[${index}].RedeemPasscode`}
                        label="Passcode"
                      />
                      {fields.length >= 1 && (
                        <img
                          src={deleteStepIcon}
                          alt="delete step"
                          onClick={() => {
                            remove(index);
                            methods.trigger();
                          }}
                        />
                      )}
                    </Stack>
                  </Box>
                ))}

                <Button
                  onClick={() =>
                    append({
                      Id: fields.length + 1,
                      Position: 0,
                      StartPosition: 0,
                      EndPosition: 0,
                      RedeemPasscode: "",
                      Description: "",
                      amount: "",
                      PrizeMoney: 0,
                    })
                  }
                  variant="contained"
                  style={{
                    color: "gray",
                    fontSize: 17,
                    textTransform: "unset",
                    border: "1px dashed lightgrey",
                    backgroundColor: "#287EAD05",
                    width: "100%",
                    boxShadow: "none",
                    marginLeft: "16px",
                  }}>
                  + Add
                </Button>
                {/* <Grid item xs={12}>
                  <Typography
                    marginBottom={1}
                    fontFamily={"Lato"}
                    fontSize={16}
                    fontWeight={500}>
                    Reward Range
                  </Typography>
                  {rewardRanges.map((range, index) => (
                    <Grid
                      paddingBottom={2}
                      paddingTop={2}
                      container
                      spacing={2}
                      key={index}>
                      <Grid item xs={2}>
                        <TextInput
                          label="Start Position"
                          value={range.startPosition}
                          onChange={(e) =>
                            handleChange(index, "startPosition", e.target.value)
                          } // Fix the typo here
                          error={!!errors.rewardRanges[index]?.startPosition}
                          helperText={errors.rewardRanges[index]?.startPosition}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextInput
                          label="End Position"
                          value={range.endPosition}
                          onChange={(e) =>
                            handleChange(index, "endPosition", e.target.value)
                          }
                          error={!!errors.rewardRanges[index]?.endPosition}
                          helperText={errors.rewardRanges[index]?.endPosition}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextInput
                          label="Description"
                          value={range.description}
                          onChange={(e) =>
                            handleChange(index, "description", e.target.value)
                          }
                          error={!!errors.rewardRanges[index]?.description}
                          helperText={errors.rewardRanges[index]?.description}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextInput
                          label="Amount ($)"
                          value={range.prizeMoney}
                          onChange={(e) =>
                            handleChange(index, "amount", e.target.value)
                          }
                          error={!!errors.rewardRanges[index]?.amount}
                          helperText={errors.rewardRanges[index]?.amount}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <TextInput
                          label="Passcode"
                          value={range.redeemPasscode}
                          onChange={(e) =>
                            handleChange(
                              index,
                              "redeemPasscode",
                              e.target.value
                            )
                          }
                          error={!!errors.rewardRanges[index]?.redeemPasscode}
                          helperText={
                            errors.rewardRanges[index]?.redeemPasscode
                          }
                          fullWidth
                        />
                      </Grid>
                      <div
                        style={{
                          display: "flex",
                          marginTop: "10px",
                          marginLeft: "20px",
                          justifyContent: "center", // Centers horizontally
                          alignItems: "center", // Centers vertically
                          // flex: 1
                        }}>
                        <img
                          onClick={() => handleRemoveRewardRange(index)}
                          src={deleteIcon}
                          alt="delete"
                        />
                      </div>
                    </Grid>
                  ))}
                  <Button
                    onClick={handleAddRewardRange}
                    variant="contained"
                    style={{
                      color: "gray",
                      fontSize: 17,
                      textTransform: "unset",
                      border: "1px dashed lightgrey",
                      backgroundColor: "transparent",
                      width: "100%",
                      boxShadow: "none",
                    }}>
                    + Add
                  </Button>
                </Grid> */}
                <Grid item xs={12}>
                  <RHFDatepicker name="RewardExpiryDate" fullWidth />
                  {/* <RHFTextField name="RewardExpiryDate" fullWidth /> */}
                </Grid>
                <Grid item xs={12}>
                  <Grid container justifyContent="flex-end">
                    <Button
                      variant="contained"
                      className="submit-button"
                      type="submit"
                      sx={{
                        background:
                          "linear-gradient(90deg, rgba(236, 159, 5, 1) 0%, rgba(255, 78, 0, 1) 100%)",
                        color: "#fff", // Ensure the text color is readable
                        "&:hover": {
                          background:
                            "linear-gradient(90deg, rgba(236, 159, 5, 1) 0%, rgba(255, 78, 0, 1) 100%)",
                        },
                        textTransform: "unset",
                      }}>
                      {reward ? "Update Reward" : "Create Reward"}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}
