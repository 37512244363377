import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import edit from "../../../assets/svg/edit-icon.svg";
import Spinner from "../../../components/Spinner";
import ToggleSwitch from "../../../components/ToggleSwitch"; // Assuming you have a custom toggle switch
import TextInput from "../../../components/TextInput";
import CustomTable from "../../../components/MuiTable/CustomTable";
import CreateRewards from "./createRewards";
import { useRewards } from "../../../queries/rewards";
import dayjs from "dayjs";
import EditRewards from "./editRewards";

export default function Rewards() {
  const [open, setOpen] = useState(false);
  const [selectedReward, setSelectedReward] = useState(null);
  const [editPop, setEditPop] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const { rewards, isLoading } = useRewards(page, pageSize);

  const rewardsWithId = rewards?.map((reward, index) => ({
    ...reward,
    rewardExpiryDate: dayjs(reward.rewardExpiryDate).format("YYYY-MM-DD"),
    id: index + 1,
  }));

  const onClick = (reward) => {
    setEditPop(true);
    setSelectedReward(reward);
  };
  const handleToggleChange = (newStatus) => {
    console.log("Toggle switched to:", newStatus ? "ON" : "OFF");
  };

  const columns = (onClick) => [
    { field: "id", headerName: "Id#", flex: 0.2 }, // Adjusted flex value for consistent sizing
    { field: "name", headerName: "Name", flex: 0.3 },
    { field: "description", headerName: "Description", flex: 0.5 },
    { field: "rewardExpiryDate", headerName: "Expiration date", flex: 0.2 },
    {
      field: "status",
      headerName: "Status",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <p
          style={{
            backgroundColor:
              params.row.status === "Open"
                ? "rgba(255, 171, 0, 1)"
                : params.row.status === "Expired"
                ? "rgba(145, 158, 171, 1)"
                : "rgba(17, 181, 12, 1)",
            borderRadius: "25px",
            paddingRight: "10px",
            paddingLeft: "10px",
            paddingTop: "2px",
            paddingBottom: "2px",
            color: "#FFF",
            fontSize: "13px",
          }}>
          {params.row.status}
        </p>
      ),
      flex: 0.4,
    },
    {
      field: "action",
      headerName: "Action",
      align: "center",
      headerAlign: "center",
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            onClick={() => onClick(params.value)}
            alt="edit"
            style={{ marginRight: "10px" }}
            src={edit}
          />
          {/* <ToggleSwitch defaultValue={false} callback={handleToggleChange} /> */}
        </div>
      ),
      flex: 0.2,
    },
  ];
  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div className="all-games-view">
      <div className="all-games-inner-view">
        <Typography fontFamily={"Lato"} fontSize={28} fontWeight={700}>
          Rewards
        </Typography>
        <div className="search-view">
          {/* <TextInput
            size="small"
            label="Search"
            variant="outlined"
            fullWidth
            style={{ marginRight: 10, fontFamily: "Lato" }}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          /> */}
          <Button
            onClick={() => setOpen(true)}
            variant="contained"
            style={{
              textTransform: "unset",
              backgroundColor: "#287EAD",
              width: "100%",
            }}>
            Create Reward
          </Button>
        </div>
      </div>
      <Box sx={{ width: "100%" }}>
        <CustomTable
          initialRows={
            rewardsWithId
              ? rewardsWithId.map((ele) => ({ action: ele.id, ...ele }))
              : []
          }
          headerBgColor={"rgba(40, 126, 173, 0.05)"}
          columns={columns(onClick, handleToggleChange)}
          rowCount={1000}
          defaultSort={"rewardExpiryDate"}
          subHeight={230}
          setPaginationModel={(data) => {
            setPage(data.page);
            setPageSize(data.pageSize);
          }}
          page={page}
          pageSize={pageSize}
          onPageChange={(newPage) => {
            console.log("newPage", newPage);
            setPage(newPage);
          }}
        />
      </Box>

      {editPop && (
        <EditRewards
          title={"Edit Reward"}
          open={editPop}
          handleClose={() => setEditPop(false)}
          reward={selectedReward}
          selectedQuery={{page: page,pageSize: pageSize}}
        />
      )}

      {open && (
        <CreateRewards
          title={"Create Reward"}
          open={open}
          handleClose={() => setOpen(false)}
          selectedQuery={{page: page,pageSize: pageSize}}
        />
      )}
    </div>
  );
}
