import * as yup from "yup";

export const editRewardSchemas = yup
  .object({
    planId: yup.string().required("Plan id is required"),
    passcode: yup.string(),
    name: yup.string().required("Reward name is required"),
    rewardType: yup.string().required("Reward type is required"),
    rewardExpiryDate: yup.string().required("Expiration date id required"),

    rewardDetails: yup
      .array()
      .of(
        yup.object().shape({
          id: yup.number(),
          position: yup.number(),
          startPosition: yup
            .number()
            .required("Start position is required")
            .positive("Start position must be positive")
            .typeError("Start position is required"),
          endPosition: yup
            .number()
            .required("End position is required")
            .positive("End position must be positive")
            .typeError("End position is required"),
          redeemPasscode: yup.string().required("Redeem passcode is required"),
          description: yup.string().required("Description is required"),
          amount: yup.string().nullable(),
          prizeMoney: yup
            .number()
            .required("Amount is required")
            .typeError("Amount is required")
            .positive("Amount must be positive"),
        })
      )
      .min(1, "At least one reward detail is required"),
  })
  .required();
