import React from "react";
import { LoaderIcon } from "react-hot-toast";

const Spinner = () => {
  return (
    <div className='loaderIcon' >
        <LoaderIcon style={{height:40,width:40,fontWeight:'bold',borderWidth:5}} /> 
    </div>
  );
};

export default Spinner;