import { Box } from "@mui/material";
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import React, { useState } from "react";

function CustomTable({
  initialRows,
  columns,
  headerBgColor,
  defaultSort,
  setPaginationModel,
  rowCount,
  subHeight,
  paginationMode,
  hideFooter = false,
  isLoading = false,
  ...rest
}) {
  const [rows, setRows] = useState([""]);
  const [selectedRows, setSelectedRows] = useState([]);

  const handleSelectionChange = (selectionModel) => {
    const newSelectedRow = selectionModel[0];
    if (newSelectedRow != null && !selectedRows.includes(newSelectedRow)) {
      setSelectedRows([...selectedRows, newSelectedRow]);
      setRows(
        rows.map((row) =>
          row.id === newSelectedRow ? { ...row, disabled: true } : row
        )
      );
    }
  };

  const getRowClassName = (params) => {
    return params.indexRelativeToCurrentPage % 2 == 0
      ? "inactive-row-table"
      : "active-row-table";
  };

  const theme = createTheme({
    components: {
      MuiDataGrid: {
        styleOverrides: {
          root: {
            "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-virtualScrollerRenderZone, & .MuiDataGrid-virtualScrollerContent":
              {
                height: `calc(96vh - ${subHeight}px) !important`,
                overflowY: "auto",
              },
            "& .active-row-table": {
              background: "rgba(40, 126, 173, 0.02)",
            },
            "& .MuiDataGrid-topContainer::after": {
              backgroundColor: "transparent",
            },
            "& .MuiDataGrid-row": {
              maxHeight: "56px !important",
              minHeight: "56px !important",
              height: "56px !important",
              borderBottom: "1px solid rgba(40, 126, 173, 0.12)",
              "--rowBorderColor": "transparent",
            },
            "& .MuiDataGrid-row.Mui-selected": {
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: "transparent",
              },
            },

            "& .MuiDataGrid-row.Mui-selected.Mui-selected:hover": {
              backgroundColor: "transparent",
            },
            "& .MuiDataGrid-row:focus, & .MuiDataGrid-cell:focus": {
              outline: "none",
            },
            "& .MuiDataGrid-row:hover": {
              backgroundColor: "transparent",
            },
            "& .MuiDataGrid-row.disabled": {
              pointerEvents: "none",
            },
            "& .MuiDataGrid-columnSeparator": {
              display: "none",
            },
            "& .MuiDataGrid-cell": {
              fontSize: "14px",
              color: "#637381 !important",
              display: "flex",
              alignItems: "center",
              fontFamily: "Lato",

              flexWrap: "wrap",
              height: "auto",
              minHeight: "inherit",
              maxHeight: "inherit",
              lineHeight: "20px",
              whiteSpace: "normal",
            },
            "& .MuiDataGrid-columnHeader": {
              backgroundColor: headerBgColor ? headerBgColor : "#F5F5F9",
              color: "#1B3E61",
              fontSize: "14px",
              fontFamily: "Lato",
              height: "44px  !important",
            },
            "& .MuiDataGrid-columnHeaderTitle": {
              fontSize: "14px !important",
            },
            "& .MuiDataGrid-columnGroupHeader": {
              backgroundColor: "#F5F8FC !important",
              color: "#fff",
              outline: "none",
            },

            "& .MuiDataGrid-columnHeader--filledGroup .MuiDataGrid-columnHeaderTitleContainerContent":
              {
                margin: "auto !important",
                paddingLeft: "0px",
              },
            "& .MuiDataGrid-columnHeader--filledGroup .MuiDataGrid-columnHeaderTitleContainer":
              {
                borderBottomWidth: "1px !important",
                backgroundColor: headerBgColor ? headerBgColor : "#F5F5F9",
                textWrap: "wrap",
              },
            "& .MuiDataGrid-columnHeader--filledGroup": {
              textAlign: "center",
            },
            "& .MuiDataGrid-columnHeader--filledGroup .MuiDataGrid-columnHeaderTitle":
              {
                fontWeight: "700 !important",
                textWrap: "wrap",
              },
            "& .MuiDataGrid-filler": {
              height: "0px !important",
            },
            "& .MuiDataGrid-virtualScroller": {
              overflowY: "hidden !important",
            },

            "& .MuiDataGrid-scrollbar--vertical": {
              overflowY: "hidden !important",
              display: "none !important",
            },
            "& .MuiDataGrid-scrollbarFiller": {
              display: "none !important",
            },
            "& .MuiDataGrid-main": {
              border: "1px solid #287EAD1F",
              borderRadius: "0px 0px 8px 8px",
            },
            "& .MuiDataGrid-footerContainer": {
              border: "none",
              backgroundColor: "rgba(246, 252, 255, 1)",
            },
            "& .MuiDataGrid-cell:focus-within": {
              outline: "none",
            },
          },
        },
      },
    },
  });

  return (
    <Box>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Box>
            <DataGrid
              rows={initialRows}
              getRowClassName={getRowClassName}
              columns={columns}
              disableColumnFilter
              disableColumnMenu
              disableSelectionOnClick={true}
              onSelectionModelChange={(newSelection) =>
                handleSelectionChange(newSelection)
              }
              paginationMode={paginationMode ? paginationMode : "server"}
              selectionModel={selectedRows}
              rowCount={Number(rowCount)}
              initialState={{
                pagination: {
                  paginationModel: { ...rest },
                },
                sorting: {
                  sortModel: [{ field: defaultSort, sort: "asc" }],
                },
              }}
              autoHeight
              pageSizeOptions={[10, 20, 50, 100]}
              onPaginationModelChange={setPaginationModel}
              checkboxSelection={false}
              hideFooterSelectedRowCount
              hideFooterPagination={hideFooter}
              loading={isLoading}
              sx={{
                border: 0,
              }}
            />
          </Box>
        </ThemeProvider>
      </StyledEngineProvider>
    </Box>
  );
}

export default CustomTable;
