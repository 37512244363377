import { useQuery } from "react-query";
import { getGameDetails } from "../services/game/gameDetails";

export const useGameDetail = (id) => {
  const { data, isLoading } = useQuery({
    queryKey: ["gameDetails", id],
    queryFn: () => getGameDetails(id),
  });

  return { data, isLoading };
};
