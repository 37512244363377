import { useQuery } from "react-query";
import { getGameDetails } from "../services/game/gameDetails";
import { getGroupId } from "../services/game/groupID";

export const useGroupIds = (id) => {
  const { data, isLoading } = useQuery({
    queryKey: ["groupId"],
    queryFn: () => getGroupId(),
  });
  const groupIds =  data?.data?.d ? JSON.parse(data?.data?.d)?.Table : ''
// console.log("in useGroupIds == ", JSON.parse(data?.data?.d)?.Table)
  return { groupIds, isLoading };
};
