import { useQuery } from "react-query";
import { getGameDetails } from "../services/game/gameDetails";
import { getTemplates } from "../services/game/template/apiDefaultTemplate";

export const useDefaultTemplate = (id) => {
  const { data, isLoading } = useQuery({
    queryKey: ["defaultTemplate"],
    queryFn: () => getTemplates(),
  });

  const templates = data?.data?.Table;

  return { templates, isLoading };
};
