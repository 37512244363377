import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import edit from "../../../assets/svg/edit-icon.svg";
import "../../../styles/global.css";
import "./games.css";
import { deleteGameFromTable, getAllGames } from "../../../services/job";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import renderParticipants from "../../../components/ProgressBar/ProgressBar";
import moment from "moment";
import Spinner from "../../../components/Spinner";
import CustomTable from "../../../components/MuiTable/CustomTable";
import { useGames } from "../../../queries/games";
export default function Games() {
  const [filter, setFilter] = useState("OPEN");
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const navigate = useNavigate();

  const { games, isLoading } = useGames(filter, page, pageSize);
  console.log(games, "games");
  // Map and format the data
  const rows =
    games?.Table?.map((game) => ({
      ...game,
      id: game.GameID,
      RegistrationFee: parseFloat(game.RegistrationFee).toFixed(2),
      TotalPrizeMoney: parseFloat(game.TotalPrizeMoney).toFixed(2),
      StartDateTime: moment(game.StartDateTime).format("M/D/YYYY h:mm A"),
      EndDateTime: moment(game.EndDateTime).format("M/D/YYYY h:mm A"),
    })) || [];

  const totalRecords = games?.Table?.[0]?.count || 0;
  console.log(rows, "rows", totalRecords, "totalRecords");
  // Dynamic column generation based on the filter
  const getColumns = () => {
    const columns = [
      { field: "GameID", headerName: "#ID", flex: 1 },
      { field: "Description", headerName: "Name", flex: 2 },
    ];

    switch (filter) {
      case "OPEN":
        columns.push(
          { field: "StartDateTime", headerName: "Start Date Time", flex: 1.5 },
          { field: "EndDateTime", headerName: "End Date Time", flex: 1.5 },
          {
            field: "TotalPrizeMoney",
            headerName: "Total Prize",
            flex: 1,
            headerAlign: "right",
            align: "right",
          },
          {
            field: "NumRegistered",
            headerName: "Participants",
            headerAlign: "center",
            width: 150,
            align: "right",
            renderCell: renderParticipants,
          },
          {
            field: "action",
            headerName: "Action",
            headerAlign: "center",
            align: "right",
            width: 150,
            sortable: false,
            renderCell: renderActions,
          }
        );
        break;

      case "RUNNING":
      case "COMPLETED": // Similar columns for both "RUNNING" and "COMPLETED"
        columns.push(
          { field: "EndDateTime", headerName: "End Date Time", flex: 2 },
          {
            field: "RegistrationFee",
            headerName: "Total Registration Fee",
            headerAlign: "right",
            flex: 2,
            align: "right",
          },
          {
            field: "TotalPrizeMoney",
            headerName: "Total Prize",
            headerAlign: "right",
            flex: 1.5,
            align: "right",
          },
          {
            field: "NumRegistered",
            headerName: "Participants",
            headerAlign: "right",
            flex: 1.5,
            align: "right",
          },
          {
            field: "PayoutType",
            headerName: "Payout",
            flex: 1.5,
            headerAlign: "right",
            align: "right",
          },
          {
            field: "action",
            headerName: "Action",
            headerAlign: "center",
            align: "right",
            width: 150,
            sortable: false,
            renderCell:
              filter === "RUNNING"
                ? renderCompletedActions
                : renderCompletedActions, // You can modify this if different logic is required
          }
        );
        break;

      case "CANCELLED":
        columns.push(
          { field: "StartDateTime", headerName: "Start Date Time", flex: 2 },
          { field: "EndDateTime", headerName: "End Date Time", flex: 2 },
          {
            field: "NumRegistered",
            headerAlign: "center",
            headerName: "Participants",
            flex: 1.5,
            renderCell: renderParticipants,
          },
          {
            field: "RegistrationFee",
            headerName: "Total Registration Fee",
            flex: 1.5,
            align: "right",
          },
          {
            field: "TotalPrizeMoney",
            headerName: "Total Prize",
            headerAlign: "right",
            flex: 1.5,
            align: "right",
          }
        );
        break;

      default:
        return columns; // If no filter is matched, return the base columns
    }

    return columns;
  };

  const renderActions = (params) => (
    <div style={{ textAlign: "center", width: "100%" }}>
      <img
        onClick={() => navigate(`/edit_game/${params.row.GameID}`)}
        src={edit}
        className="cursor-pointer action-icon"
        alt="Edit"
        style={{
          marginRight: 10,
          cursor: "pointer",
          transition: "background-color 0.3s, transform 0.3s",
          borderRadius: "4px",
          padding: "4px",
          border: "1px solid transparent",
        }}
      />
    </div>
  );

  const renderCompletedActions = (params) => (
    <div style={{ textAlign: "center", width: "100%" }}>
      <IconButton onClick={() => navigate(`/edit_game/${params.row.GameID}`)}>
        <RemoveRedEyeIcon />
      </IconButton>
    </div>
  );

  // if (isLoading) {
  //   return <Spinner />;
  // }
  return (
    <div className="all-games-view">
      <div className="all-games-inner-view">
        <Typography fontFamily={"Lato"} fontSize={28} fontWeight={700}>
          {filter == "OPEN"
            ? "Pending"
            : filter == "RUNNING"
            ? "Running"
            : filter == "COMPLETED"
            ? "Completed"
            : "Cancelled"}{" "}
          Games
        </Typography>
        <div className="header-filter">
          <div className="search-view">
            <Typography
              flex={1}
              display={"inline"}
              marginRight={1}
              color="rgba(81, 92, 99, 1)"
              fontFamily={"Lato"}
              fontSize={12}
              fontWeight={400}
              className="filter-label-text"
            >
              Filter By:
            </Typography>
            <FormControl
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& fieldset": {
                    borderColor: "rgba(40, 126, 173, 0.12)", // Default border color
                  },
                  "&:hover fieldset": {
                    borderColor: "rgba(40, 126, 173, 0.12)", // Hover border color
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "rgba(40, 126, 173, 0.12)", // Focus border color
                  },
                },
              }}
              variant="outlined"
              size="small"
              fullWidth
              style={{ marginRight: 10, float: "none" }}
            >
              <Select
                value={filter}
                sx={{ fontSize: "14px", color: "rgba(81, 92, 99, 0.8)" }}
                onChange={(e) => {
                  setPage(0);
                  setPageSize(10);
                  setFilter(e.target.value);
                }}
                displayEmpty // Ensures an empty placeholder is shown
              >
                <MenuItem sx={{ fontSize: "14px" }} value="OPEN">
                  Pending
                </MenuItem>
                <MenuItem sx={{ fontSize: "14px" }} value="RUNNING">
                  Running
                </MenuItem>
                <MenuItem sx={{ fontSize: "14px" }} value="COMPLETED">
                  Completed
                </MenuItem>
                <MenuItem sx={{ fontSize: "14px" }} value="CANCELLED">
                  Cancelled
                </MenuItem>
              </Select>
            </FormControl>

            <Button
              onClick={() => navigate("/create_game")}
              variant="contained"
              style={{
                textTransform: "unset",
                backgroundColor: "#287EAD",
                width: "100%",
              }}
            >
              Create Game
            </Button>
          </div>
        </div>
      </div>

      {isLoading ? (
        <div className="table-loader"><Spinner /></div>
      ) : (
        <div style={{ overflowX: "hidden" }}>
          <Box sx={{ width: "100%", flex: 1, overflowX: "hidden" }}>
            <CustomTable
              initialRows={rows}
              headerBgColor={"rgba(40, 126, 173, 0.05)"}
              columns={getColumns()}
              // rowCount={rows.length}
              defaultSort={"GameID"}
              subHeight={230}
              rowCount={totalRecords}
              setPaginationModel={(data) => {
                setPage(data.page);
                setPageSize(data.pageSize);
              }}
              page={page}
              pageSize={pageSize}
              onPageChange={(newPage) => {
                console.log("newPage", newPage);
                setPage(newPage);
              }}
              isLoading={isLoading}
            />
          </Box>
        </div>
      )}
    </div>
  );
}
