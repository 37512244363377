import React from "react";
import { Box, Typography } from "@mui/material";
import CustomTable from "./MuiTable/CustomTable";

const LeaderboardTable = ({ leaderboardData }) => {
  const columns = [
    { field: "rank", headerName: "#Rank", flex: 2 },
    { field: "username", headerName: "User Name", flex: 2 },
    {
      field: "score",
      headerName: "Score",
      flex: 1.5,
      align: "right",
      headerAlign: "right",
    },
  ];

  const formattedData = leaderboardData.map((item, index) => ({
    id: item.ID,
    rank: index + 1,
    username: item.Email,
    score: parseFloat(item.SCORE).toFixed(2),
  }));

  return (
    <Box fullWidth width={"100%"}>
      <Typography
        marginBlock={"20px"}
        fontFamily={"Lato"}
        fontSize={25}
        fontWeight={700}
      >
        Leaderboard
      </Typography>
      <CustomTable
       defaultSort={"GameID"}
       setPaginationModel={(data) => {
        //  setPage(data.page);
        //  setPageSize(data.pageSize);
       }}
      page={50}
      pageSize={100}
      initialRows={formattedData}
        columns={columns}
        headerBgColor={"rgba(40, 126, 173, 0.05)"}
        rowCount={formattedData.length}
        hideFooter={true}
      />
    </Box>
  );
};

export default LeaderboardTable;
