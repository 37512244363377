import {
  Box,
  MenuItem,
  Button,
  IconButton,
  Grid,
  Typography,
  Stack,
} from "@mui/material";
import React, { useRef, useState } from "react";
import "../../../../styles/gamedetail.css";
import img from "../../../../assets/svg/image-picker-icon.png";
import deleteIcon from "../../../../assets/svg/delete-icon.svg";
import playIcon from "../../../../assets/svg/play.svg";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import { editGame } from "../../../../schemas/game/editGame";
import { RHFSelect } from "../../../../components/RHF/RHFSelect";
import RHFTextField from "../../../../components/RHF/RHFTextfield";
import RHFDatepicker from "../../../../components/RHF/RHFDatepickers";
import { RHFCheckbox } from "../../../../components/RHF/RHFCheckbox";
import { useGroupIds } from "../../../../queries/groupId";
import RHFTimePicker from "../../../../components/RHF/RHFTimepicker";
import { useRewards } from "../../../../queries/rewards";
import { useDefaultTemplate } from "../../../../queries/DefaultTemplates";
import dayjs from "dayjs";
import { deleteTemplate } from "../../../../services/game/template/apiDeleteTemplate";
import toast from "react-hot-toast";
import { useQueryClient } from "react-query";
import { createGame } from "../../../../services/game/createGame";
import planeIndIcon from "./../../../../styles/images/planId_icon.svg";
import ViewPlanPopup from "../viewPlanId";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import Spinner from "../../../../components/Spinner";
import VideoComponent from "../../../../components/VideoPopup/VideoPopup";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "../../../../components/ConfirmDialog";
import { createGameSchema } from "../../../../schemas/game/createGame";
import RHFTextEditor from "../../../../components/RHF/RHFTexteditor";
export default function CreateGame() {
  const { groupIds } = useGroupIds();
  const { rewards } = useRewards();
  const { templates } = useDefaultTemplate();
  const queryClient = useQueryClient();
  const [viewPlan, setViewPlan] = useState(false);
  const [viewVideo, setViewVideo] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [templateId, setTemplateId] = useState(false);
  const [templateEvent, setTemplateEvent] = useState(false);
  const bannerInputRef = useRef(null);
  const leaderboardBannerRef = useRef(null);

  const navigate = useNavigate(null);
  const methods = useForm({
    resolver: yupResolver(createGameSchema),
    defaultValues: {
      Description: "",
      template: "",
      GroupID: "",
      GameDetail: "",
      MinPlayers: "",
      MaxPlayers: "",
      InGameTrading: "",
      VideoURL: "",
      RegistrationFee: "",
      TotalPrizeMoney: "0",
      PayoutType: "",
      PlanId: "",
      Latitude: "",
      Longitude: "",
      IsSaveasTemplate: false,
      LeaderboardBanner: "",
      BannerFile: "",
    },
  });
  const handlePopup = () => {
    setOpen(true);
  };

  const handleDelete = () => {
    setOpen(false);
    templateEvent.stopPropagation();
    deleteTemplate(templateId)
      .then((res) => {
        console.log("delete templates response == ", res);
        toast.success(res.data);
        queryClient.invalidateQueries({
          queryKey: ["defaultTemplate"],
        });
      })
      .catch((err) => {
        toast.error("Error in deleting template");
        console.log("delete templateerror === ", err);
      });
  };

  const handleImageUpload = (event, key) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        console.log("result of image == ", reader.result);
        methods.setValue(key, reader.result);
        console.log("image file === ", file);
      };
      reader.readAsDataURL(file);
    }
  };

  const triggerFileInput = (inputRef) => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const onSubmit = (formData) => {
    console.log("form data === ", formData);

    // Handle BannerFile and LeaderboardBanner
    if (typeof formData.BannerFile === "string") {
      formData.BannerFileURL = formData.BannerFile; // Assign the string value to BannerFileURL
      formData.BannerFile = null; // Set BannerFile to null
    }

    if (typeof formData.LeaderboardBanner === "string") {
      formData.LeaderboardBannerURL = formData.LeaderboardBanner; // Assign the string value to LeaderboardBannerURL
      formData.LeaderboardBanner = null; // Set LeaderboardBanner to null
    }

    const startDate = formData.startDate; // Assuming this comes from your date field
    const startTime = formData.startTime; // Assuming this comes from your start time field
    const endTime = formData.endTime; // Assuming this comes from your end time field

    // Combine date and time into the desired format
    const StartDateTime = dayjs(
      `${dayjs(startDate).format("YYYY-MM-DD")} ${dayjs(startTime).format(
        "HH:mm:ss.SSS"
      )}`
    ).format("YYYY-MM-DD HH:mm:ss.SSS");

    const EndDateTime = dayjs(
      `${dayjs(startDate).format("YYYY-MM-DD")} ${dayjs(endTime).format(
        "HH:mm:ss.SSS"
      )}`
    ).format("YYYY-MM-DD HH:mm:ss.SSS");

    formData = {
      ...formData,
      StartDateTime,
      EndDateTime,
    };

    console.log("form data with datetime === ", formData);
    setLoading(true);

    createGame(formData)
      .then((res) => {
        toast.success("Game Created Successfully.");
        navigate("/games");
        console.log("create game response === ", res);
      })
      .catch((err) => {
        console.log("create game error == ", err);
        toast.error("Error in creating game.");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  console.log("errors == ", methods.formState.errors);

  const handleTemplates = (id) => {
    const selectedTemplate = templates.filter((ele) => ele.ID == id)[0];
    methods.setValue("Description", selectedTemplate.GameName);
    // methods.setValue("GroupID", selectedTemplate.GroupID);
    methods.setValue("GameDetail", selectedTemplate.GameDetail);
    methods.setValue(
      "startTime",
      dayjs(selectedTemplate.StartTime, "HH:mm:ss.SSS")
    );
    methods.setValue(
      "endTime",
      dayjs(selectedTemplate.EndTime, "HH:mm:ss.SSS")
    );
    methods.setValue("MinPlayers", parseInt(selectedTemplate.MinPlayers));
    methods.setValue("MaxPlayers", parseInt(selectedTemplate.MaxPlayers));
    methods.setValue("InGameTrading", selectedTemplate.InGameTrading);
    methods.setValue("VideoURL", selectedTemplate.VideoUrl);
    methods.setValue(
      "RegistrationFee",
      parseFloat(selectedTemplate.RegistrationFee)
    );
    methods.setValue(
      "TotalPrizeMoney",
      parseFloat(selectedTemplate.TotalPrizeMoney)
    );
    methods.setValue("PayoutType", selectedTemplate.PayoutType);
    methods.setValue("LeaderboardBanner", selectedTemplate.LeaderboardBanner);
    methods.setValue("BannerFile", selectedTemplate.BannerFile);
  };
  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div>
      <Typography
        className="page-title"
        fontFamily={"Lato"}
        fontSize={28}
        fontWeight={700}>
        Create Game
      </Typography>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <Box className="game-detail-container">
            <Grid container spacing={2}>
              <Grid item xs={12} md={3}>
                <RHFSelect
                  name={"template"}
                  label="Choose a Template"
                  renderValue={(selected) => {
                    if (!selected) {
                      return "Choose a template"; // Default display when no template is selected
                    }
                  }}
                  native={false}
                  displayEmpty={true}
                  className="select-template"
                  sx={{
                    "& .MuiInputBase-root": {
                      height: "50px",
                      color: "white",
                      backgroundColor: "rgba(40, 126, 173, 1)",
                    },
                    "& .MuiInputBase-input::placeholder": {
                      color: "white",
                      fontSize: "14px", // Font size for input conten
                    },
                    padding: "0px",
                    borderRadius: "4px",
                    border: "1px solid rgba(40, 126, 173, 0.12)",
                  }}>
                  <MenuItem value="" onClick={() => methods.reset()}>
                    <em>+ Create New</em>
                  </MenuItem>
                  {templates?.map((template) => (
                    <MenuItem
                      key={template.ID}
                      sx={{ fontWeight: 400, color: "primary" }}
                      value={template.ID}
                      onClick={() => handleTemplates(template.ID)}>
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        width="100%">
                        <span style={{ width: "300px" }}>
                          {template.GameName}
                        </span>
                        <IconButton
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation();
                            setTemplateEvent(e);
                            setTemplateId(template.ID);
                            handlePopup();
                          }}
                          sx={{
                            padding: "0",
                            marginLeft: "16px",
                            visibility:
                              methods.watch("template") == template.ID
                                ? "hidden"
                                : "visible",
                          }}>
                          <img src={deleteIcon} />
                        </IconButton>
                      </Box>
                    </MenuItem>
                  ))}
                </RHFSelect>
              </Grid>

              <Grid item xs={12} md={5}>
                <RHFTextField
                  name="Description"
                  InputProps={{
                    style: {
                      color: "gray",
                    },
                  }}
                  className="required-field"
                  fullWidth
                  label="Game Name"
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <RHFSelect
                  name="GroupID"
                  label="Group ID"
                  style={{
                    color: "rgba(81, 92, 99, 1)",
                    fontSize: "14px",
                  }}>
                  {groupIds.length > 0
                    ? groupIds.map((item) => {
                        return (
                          <MenuItem
                            sx={{
                              fontSize: "14px",
                              color: "rgba(81, 92, 99, 1)",
                            }}
                            value={item.GroupID}>
                            {item.GroupID}
                          </MenuItem>
                        );
                      })
                    : ""}
                </RHFSelect>
              </Grid>

              <Grid item xs={12} md={8} height={106}>
                <RHFTextEditor
                  name="GameDetail"
                  placeholder="Enter game details"
                />
              </Grid>

              <Grid item xs={12} md={4}>
                <div className="image-section">
                  <div className="image-upload">
                    <label className="floating-label">Banner Image</label>
                    <div
                      onClick={() => {
                        triggerFileInput(bannerInputRef);
                      }}>
                      {methods.watch("BannerFile") ? (
                        <img
                          src={methods.getValues("BannerFile")}
                          alt="Banner"
                          className="uploaded-img"
                        />
                      ) : (
                        <div className="upload-placeholder">
                          <img
                            src={img}
                            alt="Upload Icon"
                            className="upload-icon"
                          />
                          <Typography
                            style={{
                              fontWeight: "normal",
                              fontSize: "14px",
                              color: "rgba(81, 92, 99, 0.6)",
                            }}>
                            Drag & Drop or{" "}
                            <span className="browse-btn">Browse</span>
                          </Typography>
                        </div>
                      )}
                      <input
                        type="file"
                        accept="image/*"
                        name="bannerFile"
                        ref={bannerInputRef}
                        style={{ display: "none" }}
                        onChange={(e) => handleImageUpload(e, "BannerFile")}
                      />
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item container spacing={2} xs={12} md={8}>
                <Grid item xs={12} md={4}>
                  <RHFDatepicker
                    name="startDate"
                    label="Date"
                    placeholder="date"
                    className="required-field"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <RHFTimePicker
                    name="startTime"
                    label="Start Time"
                    className="required-field"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <RHFTimePicker
                    className="required-field"
                    name="endTime"
                    label="End Time"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <RHFTextField
                    name="MinPlayers"
                    label="Min Players"
                    variant="outlined"
                    fullWidth
                    type="number"
                    className="required-field"
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <RHFTextField
                    name="MaxPlayers"
                    label="Max Players"
                    variant="outlined"
                    fullWidth
                    type="number"
                    className="required-field"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} md={4}>
                <div className="image-section">
                  <div className="image-upload">
                    <label className="floating-label">Leaderboard Banner</label>
                    <div
                      onClick={() => {
                        triggerFileInput(leaderboardBannerRef);
                      }}>
                      {methods.watch("LeaderboardBanner") ? (
                        <img
                          src={methods.getValues("LeaderboardBanner")}
                          alt="Leaderboard Banner"
                          className="uploaded-img"
                        />
                      ) : (
                        <div className="upload-placeholder">
                          <IconButton color="primary" component="span">
                            <img
                              src={img}
                              alt="Upload Icon"
                              className="upload-icon"
                            />
                          </IconButton>
                          <Typography
                            style={{
                              fontWeight: 400,
                              fontSize: "14px",
                              color: "rgba(81, 92, 99, 0.6)",
                            }}>
                            Drag & Drop or{" "}
                            <span
                              style={{
                                fontSize: "12px",
                                border: "1px solid rgba(40, 126, 173, 0.1)",
                                borderRadius: 8,
                                color: "rgba(40, 126, 173, 0.8)",
                                backgroundColor: "rgba(40, 126, 173, 0.1)",
                                padding: "5px",
                              }}>
                              Browse
                            </span>
                          </Typography>
                        </div>
                      )}
                      <input
                        type="file"
                        accept="image/*"
                        name="LeaderboardBanner"
                        ref={leaderboardBannerRef}
                        style={{ display: "none" }}
                        onChange={(e) =>
                          handleImageUpload(e, "LeaderboardBanner")
                        }
                      />
                    </div>
                  </div>
                </div>
              </Grid>

              <Grid item xs={12} md={4}>
                <RHFCheckbox
                  name="InGameTrading"
                  sx={{
                    "& .MuiSvgIcon-root": {
                      fontSize: 24, // Reduce checkbox size
                      borderRadius: "10px", // Optional: add border radius
                    },
                    "&.Mui-checked": {
                      color: "rgba(40, 126, 173, 1)", // Set the check icon color to red
                    },
                    "& .MuiFormControlLabel-label": {
                      fontSize: "14px", // Change the font size of the label
                      color: "rgba(81, 92, 99, 0.6)", // Change the color of the label
                    },
                  }}
                  label="Allow In Game Trading"
                  style={{ color: "rgba(81, 92, 99, 0.6)", fontSize: "12px" }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <RHFTextField
                  name="RegistrationFee"
                  label="Registration Fee($)"
                  variant="outlined"
                  type="number"
                  sx={{
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        display: "none",
                      },
                    "& input[type=number]": {
                      MozAppearance: "textfield",
                    },
                  }}
                  fullWidth
                  className="required-field"
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <RHFTextField
                  label="Latitude"
                  name="Latitude"
                  variant="outlined"
                  fullWidth
                  className="form-field"
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <RHFTextField
                  label="Longitude"
                  name="Longitude"
                  variant="outlined"
                  fullWidth
                  className="form-field"
                />
              </Grid>
              <Grid item spacing={2} container xs={12} md={8}>
                {methods.watch("RegistrationFee") > 0 && (
                  <Grid item xs={12} md={6}>
                    <RHFTextField
                      label="Total Prize Money ($)"
                      name="TotalPrizeMoney"
                      variant="outlined"
                      fullWidth
                      className="form-field"
                    />
                  </Grid>
                )}
                {methods.watch("RegistrationFee") > 0 && (
                  <Grid item xs={12} md={6}>
                    <RHFTextField
                      label="Payout Ratio"
                      name="PayoutType"
                      variant="outlined"
                      fullWidth
                      className="form-field"
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={12}>
                  <Stack direction="row">
                    <RHFTextField
                      name="VideoURL"
                      variant="outlined"
                      label="Promo Video URL"
                      fullWidth
                      className="form-field"
                    />
                    {methods.watch("VideoURL") ? (
                      <img
                        src={playIcon}
                        alt="play"
                        onClick={() => setViewVideo(true)}
                      />
                    ) : (
                      ""
                    )}
                  </Stack>
                </Grid>
                {methods.watch("RegistrationFee") == 0 && (
                  <Grid item xs={12} md={12}>
                    <Stack direction="row">
                      <RHFSelect
                        name="PlanId"
                        label="Plan ID"
                        style={{
                          color: "rgba(81, 92, 99, 1)",
                          fontSize: "14px",
                        }}>
                        {rewards?.length > 0
                          ? rewards.map((item) => {
                              return (
                                <MenuItem
                                  sx={{
                                    fontSize: "14px",
                                    color: "rgba(81, 92, 99,1)",
                                  }}
                                  value={item.planId}>
                                  {item.name}
                                </MenuItem>
                              );
                            })
                          : ""}
                      </RHFSelect>
                      {methods.watch("PlanId") ? (
                        <img
                          src={planeIndIcon}
                          alt="plan"
                          onClick={() => setViewPlan(true)}
                        />
                      ) : (
                        ""
                      )}
                    </Stack>
                  </Grid>
                )}
              </Grid>
              {methods.watch("Latitude") && methods.watch("Longitude") && (
                <Grid item xs={12} md={4}>
                  <LoadScript googleMapsApiKey="AIzaSyB7eYRHUhX2ACrnGw6seFkQRlQ_mzel53Q">
                    <GoogleMap
                      mapContainerStyle={{ width: "100%", height: "140px" }}
                      center={{
                        lat: parseFloat(methods.getValues("Latitude")) || 0,
                        lng: parseFloat(methods.getValues("Longitude")) || 0,
                      }}
                      zoom={10}>
                      <Marker
                        position={{
                          lat: parseFloat(methods.getValues("Latitude")) || 0,
                          lng: parseFloat(methods.getValues("Longitude")) || 0,
                        }}
                      />
                    </GoogleMap>
                  </LoadScript>
                </Grid>
              )}
            </Grid>
            <div className="flex-container">
              <RHFCheckbox
                name="IsSaveasTemplate"
                sx={{
                  "& .MuiSvgIcon-root": {
                    fontSize: 24, // Reduce checkbox size
                    borderRadius: "10px", // Optional: add border radius
                  },
                  "&.Mui-checked": {
                    color: "rgba(40, 126, 173, 1)", // Set the check icon color to red
                  },
                  "& .MuiFormControlLabel-label": {
                    fontSize: "14px", // Change the font size of the label
                    color: "rgba(81, 92, 99, 0.6)", // Change the color of the label
                  },
                }}
                label="Save as Template"
                style={{ color: "rgba(81, 92, 99, 0.6)", fontSize: "12px" }}
              />

              <Button
                variant="contained"
                className="submit-button"
                type="submit"
                sx={{
                  background:
                    "linear-gradient(90deg, rgba(236, 159, 5, 1) 0%,  rgba(255, 78, 0, 1) 100%)",
                  color: "#fff", // Ensure the text color is readable
                  "&:hover": {
                    background:
                      "linear-gradient(90deg, rgba(236, 159, 5, 1) 0%, rgba(255, 78, 0, 1) 100%)",
                  },
                  textTransform: "unset",
                }}>
                {"Create"}
              </Button>
            </div>
          </Box>
        </form>
      </FormProvider>
      {viewVideo ? (
        <VideoComponent
          methods={methods}
          handleClose={() => setViewVideo(false)}
        />
      ) : (
        ""
      )}
      {open ? (
        <ConfirmDialog
          confirmText={"Delete"}
          title={"Delete Template"}
          onCancel={() => setOpen(false)}
          onConfirm={handleDelete}
          content={
            <p>
              Are you sure you want to delete this game template?
              <br /> This action cannot be undone.
            </p>
          }
        />
      ) : (
        ""
      )}
      {viewPlan ? (
        <ViewPlanPopup
          open={viewPlan}
          handleClose={() => setViewPlan(false)}
          id={methods.getValues("PlanId")}
        />
      ) : (
        ""
      )}
    </div>
  );
}
