import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { usePlan } from "../../../../queries/plan";
import { Box, Divider, Stack, Typography } from "@mui/material";
import closeIcon from "../../../../styles/images/cross_plan_icon.svg";

export default function ViewPlanPopup({ open, handleClose, id }) {
  const { data } = usePlan(id);
  console.log("plan details == ", data?.data);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            maxWidth: "716px", // Set the desired width here
          },
        }}>
        <DialogTitle
          id="alert-dialog-title"
          sx={{ backgroundColor: "#287EAD1A", width: "676px" }}>
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row">
              <Typography fontSize="20px" fontWeight="700">
                Reward Details
              </Typography>
              <Typography className="planId">{data?.data.planId}</Typography>
            </Stack>
            <img src={closeIcon} alt="close" onClick={handleClose}/>
          </Stack>
        </DialogTitle>
        <DialogContent sx={{ padding: "30px", marginTop: "30px" }}>
          <Stack direction="row" mb={2}>
            <Box flex={1}>
              <Typography className="planLabel">Reward Name</Typography>
              <Typography className="planText">{data?.data.name}</Typography>
            </Box>

            <Box flex={1}>
              <Typography className="planLabel">Reward Type</Typography>
              <Typography className="planText">
                {data?.data?.rewardType}
              </Typography>
            </Box>
          </Stack>
          <Divider />
          <Typography className="planText" mt={2}>
            Reward Range
          </Typography>
          <Box className="rewardRangeCont">
            {data?.data?.rewardDetails.map((ele, idx) => {
              return (
                <Stack
                  direction="row"
                  sx={{ borderTop: idx == 0 ? "" : "1px solid #287EAD1F" }}
                  p={"16px 8px 16px 31px"}>
                  <Box flex={1}>
                    <Typography className="planLabel">Position</Typography>
                    <Typography className="planSubText">
                      {ele.startPosition}-{ele.endPosition}
                    </Typography>
                  </Box>

                  <Box flex={1}>
                    <Typography className="planLabel">Description</Typography>
                    <Typography className="planSubText">
                      {ele.description}
                    </Typography>
                  </Box>

                  <Box flex={1}>
                    <Typography className="planLabel">Passcode</Typography>
                    <Typography className="planSubText">
                      {ele.redeemPasscode}
                    </Typography>
                  </Box>
                  <Divider />
                </Stack>
              );
            })}
          </Box>
        </DialogContent>
        <DialogActions>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
