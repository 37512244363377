import Api from "../axios";

export const updateGame = async (data) => {
  return await Api.put("/Game/Update", data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Accept: "*/*",
      "ngrok-skip-browser-warning": "1",
    },
  });
};
