// DatePickerComponent.jsx
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { DatePicker } from "@mui/x-date-pickers";

const RHFDatepicker = ({ name ,label,className,disabled}) => {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={null}
      render={({ field, fieldState: { error } }) => (
        <DatePicker
        label={label}
        disabled={disabled} 
        className={className}
        sx={{
          "& .MuiInputBase-root": {
            '& fieldset': {
             
              borderColor: 'rgba(40, 126, 173, 0.12)',  // Default border color
            },
            height: "52px !important",
            backgroundColor: "#fff",
            color:'rgba(81, 92, 99, 1)',
            fontSize:'14px',
            '&:hover fieldset': {
              borderColor: 'rgba(40, 126, 173, 0.12)',  // Hover border color
            },
          },
            '&.Mui-focused fieldset': {
              borderColor: 'rgba(40, 126, 173, 0.12)',  // Focus border color
            },
            "& .MuiSvgIcon-root": {
              color:'rgba(81, 92, 99, 0.6)', 
              fontSize:'18px' // Custom icon color
            }
        }}
          slotProps={{
            textField: {
              fullWidth: true,
              variant: "outlined",
              error: !!error,
              
              helperText: error?.message,
              InputLabelProps: {
                style: { 
                  fontSize: '14px', // Adjust font size
                  color:'rgba(81, 92, 99, 0.6)' // Custom color for the label
                },
              },
              style: { height: "50px !important" ,}, // Adjust the height here
            },
          }}
          {...field}
        />
      )}
    />
  );
};

export default RHFDatepicker;