import React, { useState, useEffect } from 'react';
import { Grid, Dialog, DialogContent, DialogActions, DialogTitle, Button, Stack, Typography } from '@mui/material';

const VideoComponent = ({ methods,handleClose }) => {
  return (
          <Dialog open={true} onClose={handleClose} maxWidth="lg" fullWidth>
            <DialogTitle>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h6">Promo Video</Typography>
                <Button onClick={handleClose}>Close</Button>
              </Stack>
            </DialogTitle>
            <DialogContent>
                <video controls autoPlay loop muted width="100%">
                <source src={methods.getValues('VideoURL')} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
            </DialogContent>
          </Dialog>
  );
};

export default VideoComponent;
