import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import {
  Controller,
  FormProvider,
  useFieldArray,
  useForm,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import RHFTextField from "../../../../components/RHF/RHFTextfield";
import { RHFSelect } from "../../../../components/RHF/RHFSelect";
import { useRewards } from "../../../../queries/rewards";
import { createRewardSchemas } from "../../../../schemas/rewards/createRewards";
import deleteStepIcon from "../../../../styles/images/delete_reward_step.svg";
import RHFDatepicker from "../../../../components/RHF/RHFDatepickers";
import { postCreateReward } from "../../../../services/rewards/apiCreateRewards";
import { useRewardDetail } from "../../../../queries/rewardDetails";
import { editRewardSchemas } from "../../../../schemas/rewards/editReward";
import dayjs from "dayjs";
import EditRewardForm from "./editRewardForm";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function EditRewards({
  open,
  children,
  handleClose,
  callback,
  loading,
  title,
  btnTitle,
  reward,
  plan,
  btnColor = "#1B3E61",
  selectedQuery,
}) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { data, isLoading } = useRewardDetail(reward);

  return (
    <React.Fragment>
      <BootstrapDialog
        fullWidth
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
        open={open}
        fullScreen={fullScreen}
        sx={{
          height: "100vh",
          "& .MuiPaper-root": {
            borderRadius: "12px",
            maxWidth: "716px",
          },
        }}>
        <DialogTitle
          sx={{
            m: 0,
            padding: "16px",
            color: "rgba(27, 62, 97, 1)",
            fontFamily: "Lato",
            fontWeight: 600,
            bgcolor: "rgba(40, 126, 173, 0.1)",
            lineHeight: "1",
          }}
          id="responsive-dialog-title"
          className="dialog-title">
          {title}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
        <DialogContent dividers className="dialog-content-form">
          {data?.data && (
            <EditRewardForm
              selectedQuery={selectedQuery}
              data={data.data}
              handleClose={handleClose}
            />
          )}
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}
