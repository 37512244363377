import { useQuery } from "react-query";
import { getGames } from "../services/game/apiGetGames";

export const useGames = (filter, page, pageSize) => {
  const { data, isLoading } = useQuery({
    queryKey: ["getGames", filter, page, pageSize],
    queryFn: () => getGames(filter, page, pageSize),
  });
  let jsonString = data?.data
  const games = jsonString ? JSON.parse(jsonString) : ''
  return { games, isLoading };
};
